import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  "data-testid": "placeholder-icon",
  viewBox: "0 0 14 14",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M1.001 2.723C1.001 1.772 1.772 1 2.723 1h2.444a.5.5 0 1 1 0 1H2.723a.72.72 0 0 0-.722.722v2.444a.5.5 0 0 1-1 0zM8.333 1.5a.5.5 0 0 1 .5-.5h2.444C12.23 1 13 1.77 13 2.723v2.444a.5.5 0 0 1-1 0V2.723A.72.72 0 0 0 11.277 2H8.834a.5.5 0 0 1-.5-.5ZM1.501 8.333a.5.5 0 0 1 .5.5v2.444c0 .399.323.722.722.722h2.444a.5.5 0 0 1 0 1H2.723a1.72 1.72 0 0 1-1.722-1.722V8.833a.5.5 0 0 1 .5-.5m10.999 0a.5.5 0 0 1 .5.5v2.444c0 .951-.771 1.722-1.723 1.722H8.834a.5.5 0 1 1 0-1h2.444a.72.72 0 0 0 .723-.722V8.833a.5.5 0 0 1 .5-.5Z" }, null, -1)
  ])))
}
export default { render: render }